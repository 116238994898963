import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout";
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";

export const query = graphql`
  {
    prismicPage(uid: {eq: "about"}) {
      uid
      type
      data {
        header_text
        header_image {
          gatsbyImageData
        }
        page_content {
          richText
        }
      }
    }
  }
`

const About = (props) => {

  return (
    <Layout>
      <Container className="aboutPage py-5">
        <div className="aboutImageWrapper pt-4">
          <GatsbyImage
            image={props.data.prismicPage.data.header_image.gatsbyImageData}
            alt="about image"
          />
        </div>
        <div className="aboutTextWrapper mx-3 mx-md-5">
          <h1 className="aboutHeaderText">{props.data.prismicPage.data.header_text}</h1>
          <div className="aboutTextContent mt-4">
            <PrismicRichText field={props.data.prismicPage.data.page_content.richText} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default About;
